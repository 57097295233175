<template>
  <div class="home">
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :loader="loader"
      :color="color"
    />
    <Navbar v-if="this.authenticated" />
    <Callback v-if="!this.authenticated" />
    <ContentArea v-if="this.authenticated" />
    <router-view />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import ContentArea from '@/components/ContentArea'
import Callback from '@/components/Callback'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { scenarioStore } from '@/store/scenarioStore.js'
import axios from 'axios'
import { mapMutations } from 'vuex'

export default {
  name: 'Home',
  data () {
    return {
      authenticated: false,
      isLoading: true,
      fullPage: true,
      loader: 'bars',
      color: '#ff681d',
      backendURL: process.env.VUE_APP_BACKEND_URL
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters.authenticated,
      (newValue, oldValue) => {
        if (newValue !== null) {
          this.isLoading = false
          this.authenticated = true
          this.getDemoPageDetails()
        }
      }
    )
  },
  methods: {
    ...mapMutations(['SELECT_CATEGORY']),
    getDemoPageDetails () {
      this.isLoading = true
      return axios({
        method: 'GET',
        params: this.$route.params,
        url: this.backendURL + 'dynamodb/scenarios',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('msal.idtoken')
        },
        responseType: 'json'
      }).then(
        (result) => {
          const bodyItems = result.data.body
          const featured = bodyItems[0].description.featured ? 'featured' : ''
          scenarioStore.set(
            bodyItems[0].description.scenarioId,
            bodyItems[0].description.name,
            bodyItems[0].description.picture,
            bodyItems[0].description.description,
            bodyItems[0].description.link,
            bodyItems[0].description.user,
            bodyItems[0].description.password,
            bodyItems[0].description.training_script,
            bodyItems[0].description.demo_script,
            bodyItems[0].description.video,
            bodyItems[0].description.demo_type,
            bodyItems[0].lastPublishedDate,
            bodyItems[0].description.snapshot,
            featured + '-details'
          )
          this.SELECT_CATEGORY(bodyItems[0].description.category)
          this.isLoading = false
        }
      )
    }
  },
  created () {
    if (this.authenticated) this.getDemoPageDetails()
  },
  components: {
    Loading,
    Navbar,
    ContentArea,
    Callback
  }
}
</script>

<style>
.home {
  display: flex;
  flex-direction: column;
}
</style>
